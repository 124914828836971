// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "./_variables";

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

.mat-dialog-container {
  border-radius: 14px !important;
  background-color: #ffffff !important;
  height: 100%;
}

.mat-form-field-appearance-outline .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-width: 1px !important;
  color: #e0e0e0;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
  border: 0;
}

.mat-chip {
  font-size: 12px;
  font-weight: 400;
}

.mat-form-field-infix {
  margin-top: -6px;
}
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background: rgba(0, 158, 226, 0.3) !important;
}
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #189cd8 !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-ripple .mat-ripple-element {
  background: rgba(0, 158, 226, 0.3) !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #189cd8 !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #189cd8 !important;
}
