@import "src/app/core/scss/global";
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600");

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background: transparent !important;
  padding: 0 !important;
}
.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding: 1em 0;
}
th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: 24px !important;
}
th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
  padding-left: 24px !important;
}
.tool-tip {
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #ffffff;
}
.mat-expansion-panel:not(.mat-expanded):not([aria-disabled="true"])
  .mat-expansion-panel-header:hover {
  background: transparent !important;
}

.error.mat-snack-bar-container {
  background: #ea575a;
  opacity: 1 !important;
  color: #ffffff;
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.success.mat-snack-bar-container {
  background: #08a755;
  opacity: 1 !important;
  color: #ffffff;
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

table tr:nth-child(even) td {
  background: rgba(0, 159, 227, 0.05);
}

@media screen and (max-width: 960px) {
  th.mat-header-cell:first-of-type,
  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type {
    padding-left: 0 !important;
  }
  th.mat-header-cell:last-of-type,
  td.mat-cell:last-of-type,
  td.mat-footer-cell:last-of-type {
    padding-left: 0 !important;
  }
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
