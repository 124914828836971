@import "partial/_material_theme";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Montserrat", serif;
}

.title_budget {
  font-family: "Montserrat", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #1e1e1e;
}
